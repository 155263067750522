export const Messages = {
  failedChecks: 'Failed checks:',
  emergency: 'Emergency',
  alert: 'Alert',
  critical: 'Critical',
  error: 'Error',
  warning: 'Warning',
  notice: 'Notice',
  info: 'Info',
  debug: 'Debug',
};
